import 'isomorphic-fetch';
import isPlainObject from 'lodash/isPlainObject';

// api interface for caching data
const API = {
  cache: new Map(),
  requests: new Map()
};

const defaultFetchOptions = {
  method: 'GET',
  headers: {
    Accept: 'application/json, text/plain',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
    'Cache-Control': 'public, no-cache, max-age=31536000'
  },
  mode: 'cors',
  credentials: 'same-origin',
  referrer: 'no-referrer',
  cache: 'default'
};

/**
 * Formats response based on content-type
 * @param {Response} response
 * @returns {Promise<Object>}
 */
const parseResponse = async response => {
  const contentDisposition = response.headers.get('content-disposition');
  const isFileType = contentDisposition && contentDisposition.includes('attachment');
  const responseBody = isFileType
    ? await response.blob()
    : await response.text();
  let data = responseBody;

  if (response.status === 204) {
    return data;
  }

  let failed = false;
  if (!isFileType) {
    try {
      data = JSON.parse(responseBody);
    } catch (err) {
      console.error('Fetch error: Cannot parse response body: ', responseBody);
      failed = true;
    }
  }

  if (
    response.status === 200
    && !failed
  ) {
    const etag = response.headers.get('etag');
    if (etag) {
      const urlApiPath = response.url.replace(/.+?(?=\/api)/, '').replace(/\?.+/, '');
      API.cache.set(urlApiPath, { etag, data });
    }
  }

  if (response.status === 304) {
    const etag = response.headers.get('etag');
    if (etag) {
      console.info( // eslint-disable-line
        `%c__REQUEST__ Overriding 304 for: %c${ response.url }`,
        'color: blue',
        'font-weight: bold'
      );
      const urlApiPath = response.url.replace(/.+?(?=\/api)/, '').replace(/\?.+/, '');
      const cachedData = API.cache.get(urlApiPath);
      if (cachedData && cachedData.etag === etag) {
        return {
          ...data,
          data: cachedData.data
        };
      }
    }
  }

  if (
    response.status === 200
    && data?.status === 'error'
  ) {
    const error = new Error(data.errors.map(i => i.message).join('\n'));
    error.path = response.url;
    error.info = data;
    error.status = response.status;
    error.code = response.code;
    throw error;
  }

  if (
    failed
    || (
      response.status >= 300
      && response.status < 599
      && response.code !== 20
    )
  ) {
    const error = new Error('An error occurred while fetching the data.');
    error.path = response.url;
    error.info = data;
    error.status = response.status;
    error.code = response.code;
    if (response.location) {
      error.location = response.location;
    }
    throw error;
  }

  return data;
};

/**
 * Fetch wrapper helper
 * @param path
 * @param options
 * @returns {Promise<object>}
 */
const fetchHelper = (path = null, options = {}) => {
  if (isPlainObject(path) && !options) {
    Object.assign(options, path);
  }
  const URI = (typeof path !== 'string' && path !== null) ? path.url : path;

  let urlInstance = URI;
  if (!(URI instanceof URL)) {
    urlInstance = new URL(`${ window.location.origin }${ URI }`);
  }

  const cache = API.cache.get(options?.cacheKey || urlInstance.pathname);
  if (cache && cache.etag) {
    // Mutate options headers to assign etag
    Object.assign(options, {
      headers: {
        ...options.headers,
        'If-None-Match': cache.etag
      }
    });
  }

  if (options.params) {
    if (isPlainObject(options.params)) {
      Object.entries(options.params).forEach(([key, value]) => {
        urlInstance
          .searchParams
          .append(encodeURIComponent(key), encodeURIComponent(value));
      });
    } else {
      /* eslint no-console: ["error", { allow: ["warn", "error"] }] */
      console.error(`
        Params must be of type object.
        Instead received: ${ typeof options.params }
      `);
    }
  }

  const opts = {
    ...defaultFetchOptions,
    ...options
  };

  if (
    opts.forceCancel
    || (
      opts.method === 'GET'
      && !opts.noCancel
    )
  ) {
    // Cancel dupe not finished GET requests
    // TODO: Try to limit dupe requests in the first place, instead of making the server
    // work and dropping the connection !
    const controller = new AbortController();
    const prevReq = API.requests.get(opts?.cacheKey || urlInstance.pathname);

    if (prevReq) {
      prevReq.abort();
      console.info( // eslint-disable-line
        `%c__REQUEST__ Cancelled for URI: %c${ URI }`,
        'color: blue',
        'font-weight: bold'
      );
    }
    API.requests.set(opts?.cacheKey || urlInstance.pathname, controller);
    opts.signal = controller.signal;
  }

  const handleError = error => {
    if (error.code !== 20) {
      if (
        process.env.NODE_ENV !== 'production'
        || process.env.NODE_ENV !== 'prod'
      ) {
        console.error('__API_ERROR__', error);
      }
      throw error;
    }
  };

  return fetch(urlInstance, opts)
    .then(parseResponse)
    .catch(handleError)
    .finally(() => {
      if (API.requests.has(opts?.cacheKey || urlInstance.pathname)) {
        API.requests.delete(opts?.cacheKey || urlInstance.pathname);
      }
    });
};

export default fetchHelper;
