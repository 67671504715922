import { memo, cloneElement, useRef, useEffect, useState } from 'react';
import { node, bool } from 'prop-types';
import { createPortal } from 'react-dom';

const ModalStateManager = memo(props => {
  const { children } = props;
  const [open, setOpen] = useState(!!props.open);
  const element = useRef(null);
  const [canRender, setCanRender] = useState(false);

  useEffect(() => {
    if ('open' in props && props.open !== open) {
      setOpen(props.open);
    }
  }, [props.open]);

  useEffect(() => {
    if (typeof document !== 'undefined' && !element.current) {
      element.current = document.getElementById('root-modal');
      if (element.current) {
        setCanRender(true);
      }
    }
  }, []);

  if (!children || !canRender) {
    return null;
  }

  return createPortal(cloneElement(children, { open }), element.current);
});

ModalStateManager.propTypes = {
  open: bool,
  children: node
};

ModalStateManager.displayName = 'ModalStateManager';

export default ModalStateManager;
