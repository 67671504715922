import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams, useLocation, useNavigate, useNavigationType, useMatch } from 'react-router-dom';
import { parse } from 'query-string';
import { dequal } from 'dequal';
import omit from 'lodash/omit';

const listeners = [];
let prevLocation = {};

const staticPaths = [
  'orders',
  'addresses',
  'users',
  'feeds',
  'api-information',
  'dashboard',
  'account',
  'ecommerce-tools',
  'invoices',
  'unpaid-invoices',
  'payments'
];

const useRouter = () => {
  const navigate = useNavigate();
  const routerLocation = useLocation();
  const routerParams = useParams();
  const match = useMatch(routerLocation.pathname);
  const navigationType = useNavigationType();

  const [params, setParams] = useState(routerParams);

  useEffect(() => {
    if (
      routerLocation.key !== prevLocation.key
      // Don't push location change when applying hash !!
      && (
        routerLocation.pathname !== prevLocation.pathname
        || routerLocation.search !== prevLocation.search
      )
    ) {
      // Used for reloading back navigations to static paths
      if (
        navigationType === 'POP'
        && prevLocation.pathname
        && typeof window !== 'undefined'
      ) {
        if (
          routerLocation.pathname === ''
          || routerLocation.pathname === '/'
        ) {
          window.location.reload();
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const path of staticPaths) {
            const re = new RegExp(`^/\\b${path}\\b#?/?\?`); // eslint-disable-line
            if (re.test(routerLocation.pathname)) {
              window.location.reload();
              break;
            }
          }
        }
      }
      listeners.forEach(cb => {
        cb(routerLocation, prevLocation, navigationType);
      });
      prevLocation = routerLocation;
    }
  }, [routerLocation]);

  useEffect(() => {
    if (!dequal(params, routerParams)) {
      setParams(routerParams);
    }
  }, [routerParams]);

  const getNavigationProps = useCallback((...args) => {
    const [path, opts] = args;
    const state = path.state || opts;
    const To = {};
    if (typeof path === 'string') {
      To.pathname = path;
    } else {
      To.pathname = path.pathname;
      if (path.search) {
        To.search = path.search;
      }
      if (path.hash) {
        To.hash = path.hash;
      }
    }
    if (state) {
      return { To, state };
    }
    return { To };
  }, []);

  const history = useMemo(() => ({
    listen(fn) {
      listeners.push(fn);
      return () => {
        listeners.splice(listeners.indexOf(fn), 1);
      };
    },
    push: (...args) => {
      const { To, state } = getNavigationProps(...args);
      const p = { replace: false };
      if (state) {
        p.state = state;
      }
      navigate(To, p);
    },
    replace: (...args) => {
      const { To, state } = getNavigationProps(...args);
      const p = { replace: true };
      if (state) {
        p.state = state;
      }
      navigate(To, p);
    }
  }), []);

  return useMemo(
    () => ({
      history: {
        ...history,
        location: routerLocation
      },
      push: history.push,
      replace: history.replace,
      listen: history.listen,
      navigate,
      pathname: routerLocation.pathname,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: {
        ...parse(routerLocation.search), // Convert string to object
        ...omit(params, ['*'])
      },
      params,
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location: routerLocation
    }),
    [routerLocation, match, params]
  );
};

export default useRouter;
