import React, { memo, useMemo } from 'react';
import { node } from 'prop-types';
import { ThemeProvider } from '@emotion/react';

import defaultTheme from 'Assets/styles/theme.default';

import useUiState from 'Hooks/useUiState';

const ThemeMap = new Map();
ThemeMap.set('default', defaultTheme);

const NodThemeProvider = memo(({ children }) => {
  const { theme } = useUiState();

  const chosenTheme = useMemo(
    () => {
      if (!theme) return defaultTheme;

      if (ThemeMap.has(theme)) {
        return ThemeMap.get(theme);
      }

      console.warn(`Theme ${ theme } not found. Default theme provided !`);
      return defaultTheme;
    },
    [theme]
  );

  return (
    <ThemeProvider theme={ chosenTheme }>
      { children }
    </ThemeProvider>
  );
});

NodThemeProvider.propTypes = {
  children: node
};

export default NodThemeProvider;
