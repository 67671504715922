export const actions = {
  awb_code_clipboard: 'awb_code_clipboard',
  awb_link_clicked: 'awb_link_clicked',
  awb_downloaded: 'awb_downloaded',
  invoice_code_clipboard: 'invoice_code_clipboard',
  product_code_clipboard: 'product_code_clipboard',
  smis_platform_clicked: 'smis_platform_clicked',
  sort_orders: 'sort_orders',
  file_download: 'file_download',
  order_expand: 'order_expand',
  order_delete: 'order_reservation_delete',
  order_edit: 'order_edit',
  order_view: 'order_view',
};

export const locations = {
  orders_page: 'orders_page',
  order_details_page: 'order_details_page',
  product_details_page: 'product_details_page',
  dashboard_page: 'dashboard_page',
  rma_page: 'rma_page',
};
