import common from './common_en.json';
import products from './products_en.json';
import navigation from './navigation_en.json';
import filters from './filters_en.json';
import titles from './titles_en.json';
import profile from './profile_en.json';
import cart from './cart_en.json';
import wishlists from './wishlists_en.json';
import order from './orders_en.json';
import footer from './footer_en.json';
import alerts from './alerts_en.json';
import errors from './errors_en.json';
import pages from './pages_en.json';

export default {
  alerts,
  notifications: alerts,
  footer,
  common,
  order,
  orders: order,
  user: profile,
  users: profile,
  products,
  product: products,
  titles,
  navigation,
  filters,
  profile,
  cart,
  wishlists,
  wishlist: wishlists,
  errors,
  pages,
};
