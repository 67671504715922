import React, { memo, useEffect, useCallback, useState, useRef } from 'react';
import { createContext } from 'use-context-selector';
import { dequal } from 'dequal';
import memoize from 'micro-memoize';
import { node } from 'prop-types';

import {
  API_USER_PROFILE,
  GRID_VIEW_MODE,
} from 'Constants';
import useUser from 'Hooks/useUser';
import useFetch from 'Hooks/useFetch';

const defaultState = {
  alertsModalOpened: false,
  searchOpen: false,
  theme: 'default',
  viewMode: GRID_VIEW_MODE,
};

export const UIContext = createContext(defaultState);

const UIProvider = memo(({ children }) => {
  const { data: user, setUserData: updateUser } = useUser();
  const fetch = useFetch();
  const ref = useRef(false);
  const [uiState, setUiState] = useState({
    ...defaultState,
    ...(user?.uiState || {})
  });

  const memoizeUiState = useCallback(
    memoize(state => ({
      uiState: state,
      setUiState: newState => {
        setUiState(prevState => {
          const newMergedState = {
            ...prevState,
            ...newState
          };

          if (!dequal(newMergedState, prevState)) {
            return newMergedState;
          }

          return prevState;
        });
      }
    }), { isEqual: dequal }),
    []
  );

  useEffect(() => {
    setUiState({
      ...defaultState,
      ...(user?.uiState || {})
    });
  }, [user?.userId]);

  useEffect(() => {
    if (!user?.loggedIn) {
      setUiState({ ...defaultState });
    }
  }, [user?.loggedIn]);

  const saveUserUpdate = async () => {
    if (
      user.loggedIn
      && !dequal(uiState, user?.uiState)
    ) {
      try {
        await fetch(API_USER_PROFILE, {
          method: 'PATCH',
          body: JSON.stringify(uiState)
        });
        updateUser({ uiState });
      } catch (err) {
        console.error('There was an error updating the user after ui updated');
      }
    }
  };

  useEffect(() => {
    if (ref.current) {
      saveUserUpdate();
    } else {
      ref.current = true;
    }
  }, [uiState]);

  return (
    <UIContext.Provider value={ memoizeUiState(uiState) }>
      { children }
    </UIContext.Provider>
  );
});

UIProvider.propTypes = {
  children: node
};

UIProvider.displayName = 'UIProvider';

export default UIProvider;
