import 'carbon-components/scss/components/modal/_modal.scss';
import 'carbon-components/scss/components/button/_button.scss';

import React, { useCallback, useState } from 'react';
import { string, array, node, bool, func } from 'prop-types';
import { ModalBody, ComposedModal } from 'carbon-components-react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@emotion/react';

import LoginForm from 'Components/Login/LoginForm';
import ModalManager from 'Containers/ModalManager/ModalManager';

import LoginBackground from 'Assets/images/login-bg2.jpg';

const StyledComposedModal = styled(ComposedModal)`
  z-index: 10000 !important;
  &.loginPage {
    width: 100%;
    height: 100%;
    background: #0F233C no-repeat center url(${ LoginBackground });
  }

  .bx--modal-container {
    box-shadow: none;
    max-height: none;
    height: auto;
    background-color: #fffffff2;
  }

  @media screen and (max-width: 767px) {
    .bx--modal-container {
      position: static;
      width: calc(100vw - 30px);
    }
  }
`;

const StyledModalBody = styled(ModalBody)`
  display: flex;
  margin: 0 auto !important;
  padding: 0 !important;
  width: 100%;
  flex-direction: column;

  .form {
    width: 100%;

    .submit {
      height: 40px;
    }
  }

  .partnerShip {
    padding: 48px 25px 40px;
    margin-top: 5px;
    display: flex;
    justify-content: center;

    p { 
      padding-right: 0;
      
      a {
        outline: none;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .partnerShip {
      padding: 15px 25px 25px;
    }
  }
`;

const LoginModal = props => {
  const { size = 'sm', open = false, successMessages, canDisplayPartnership = true, error, isLoginPage, onError } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [isRecoveryMode, setIsRecoveryMode] = useState(false);
  const handleSwitch = useCallback(() => {
    onError('');
    setIsRecoveryMode(true);
  }, []);
  const handleCancel = useCallback(() => {
    onError('');
    setIsRecoveryMode(false);
  }, []);
  return (
    <ModalManager open={ open }>
      <StyledComposedModal
        className={ `LoginModal ${ isLoginPage ? 'loginPage' : '' }` }
        onClose={ e => e.preventDefault() }
        preventCloseOnClickOutside
        size={ size }
        theme={ theme }
        danger={ !!error }
      >
        <StyledModalBody theme={ theme }>
          { props?.children }
          <div className="form">
            <LoginForm
              onCancelRecovery={ handleCancel }
              isRecoveryMode={ isRecoveryMode }
              onRecovery={ handleSwitch }
              successMessages={ successMessages }
              { ...props }
            />
            { (!isRecoveryMode && canDisplayPartnership) && (
              <div className="partnerShip">
                <p>{ t('profile.notPartnerYet') } <a href="/registration" data-test="onGoToRegister">{ t('profile.becomePartner') }</a></p>
              </div>
            ) }
          </div>
        </StyledModalBody>
      </StyledComposedModal>
    </ModalManager>
  );
};

LoginModal.propTypes = {
  children: node,
  successMessages: array,
  canDisplayPartnership: bool,
  open: bool,
  error: string,
  size: string,
  isLoginPage: bool,
  onError: func,
};

LoginModal.displayName = 'LoginModalComponent';

export default LoginModal;
