import { shape, bool, string, number } from 'prop-types';

const UserPropType = shape({
  userId: number,
  email: string,
  language: string,
  defaultCurrency: string,
  withVat: bool,
  firstName: string,
  lastName: string,
  phone: string,
  defaultNavigation: number,
  sessionLifetime: number,
  cookiePolicy: number,
  dataProcessingAgreement: number,
  loggedIn: bool,
  sessionExpired: bool,
  technicalSupportEmail: string,
  accountManager: shape({
    userId: number,
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    profilePicture: string
  })
});

export default UserPropType;
