const themeDefault = {
  colors: {
    green: '#169001',
    default: '#D52B1E',
    white: '#FFF',
    black: '#000',
    blue: '#1F59A2',
    lighterBlue: '#B2C2D5',
    paleDarkBlue: '#41618A',
    darkBlue: '#0F233C',
    red: '#E21C1C',
    lightOrange: '#ff8519',
    altOrange: '#FB7501',
    orange: '#FB9236',
    shadowGray: '#F3F2F2',
    lightGray: '#AFAEAE',
    altGray: '#F8F8F8',
    anotherGray: '#C6C6C6',
    gray: '#707070',
    darkGray: '#3A3A3A',
    lightBlue: '#DDE6F2',
    pink: '#FBD7D4',
    lightRed: '#FFF2F1',
    lightPink: '#FCF3F1',
  },
  labels: {
    input: '#999999'
  },
  filters: {
    colors: {
      activeFiltersBg: '#FBFBFB',
    }
  },
  products: {
    colors: {
      borderButtons: '#547CAF',
      border: '#E2E2E2',
      quickViewButtonBg: '#F4F9F4',
      isResealed: '#498246',
      inStock: '#68A765',
      isEol: '#FB9236',
      addToFavoriteText: '#223F64',
      addToFavorite: '#F5F7F9',
      addToFavoriteBorder: '#CAD3DE',
      promotionsRibbon: '#F4F7FC'
    }
  },
  device: {
    desktop: '1920px',
    laptop: '1366px',
    tablet: '1024px',
    mobile: '767px',
  }
};

export default themeDefault;
