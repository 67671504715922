import 'carbon-components/scss/components/form/_form.scss';
import 'carbon-components/scss/components/text-input/_text-input.scss';
import 'carbon-components/scss/components/button/_button.scss';
import 'carbon-components/scss/components/loading/_loading.scss';
import 'carbon-components/scss/components/checkbox/_checkbox.scss';

import React from 'react';
import { Button, Checkbox, Form, Loading, TextInput } from 'carbon-components-react';
import uniqueId from 'lodash/uniqueId';
import { bool, array, func, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import { useTheme } from '@emotion/react';

import { CheckmarkFilled32 } from '@carbon/icons-react';
import IconCaret from 'Assets/images/caret.svg';

const StyledFormContainer = styled.div`
  flex-grow: 1;
  background-color: #ffffff00;
  padding: 24px;

  &.loginPage {
    padding: 26px 126px;
    .bx--text-input-wrapper {
      margin-top: 24px;
    }
  }

  &.reset {
    .buttons {
      width: 100%;
      margin-top: 15px;
      display: flex;
      flex-direction: column;

      button {
        font-size: 1rem;
        margin: 0;
        height: 40px;

        &.backButton {
          background: transparent;
          border: none;
          color: #0f62fe;
          font-size: 14px;
          margin-top: 48px;
          cursor: pointer;

          svg {
            transform: rotate(90deg);
            fill: #0f233c;
            margin-right: 5px;
          }
        }
      }
    }
  }

  .bx--label {
    margin-bottom: 0;
  }

  .messagePlaceholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 28px;
    text-align: center;

    .title {
      font-size: 1.75rem;
      color: inherit;
      width: 100%;
      font-weight: 700;
    }

    p {
      font-size: 0.875rem;
      line-height: 1.25rem;
      padding: 0;
    }
  }

  form {
    color: inherit;

    .submit {
      width: 100%;
      height: 40px;
      max-width: none;
      margin-top: 24px;
      padding: 0;
      justify-content: center;
      font-size: 1rem;
      text-align: center;

      .bx--loading {
        left: calc(50% - 75px);
      }

      svg {
        margin-right: 5px;
      }

      background-color: ${ props => (!props.sessionExpired && props.success)
        ? props.theme.colors.green
        : props.theme.colors.red };
    }

    .loginHelpers {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .bx--checkbox-label {
        &:before {
          border-color: inherit;
        }
      }

      button {
        &:active, &:focus {
          font-weight: bold;
        }

        color: inherit;
        outline: 0 none;
        border: 0 none;
        padding: 0;
        box-shadow: none;
        background: transparent;
      }
    }
  }

  @media screen and (max-width: 767px) {
    &.loginPage {
      padding: 10px 20px;

      .bx--text-input-wrapper {
        margin-top: 16px;
      }
    }
  }
`;

const StyledLoading = styled(Loading)`
  width: 1em;
  height: 1em;
  margin-left: 1em;
`;

const StyledTextInput = styled(TextInput)`
  margin: 6px auto;
  border: 1px solid #e2e2e2;
  border-radius: 3px;
`;

const LoginForm = props => {
  const {
    title,
    message,
    loading,
    success,
    successMessages,
    recovered,
    error,
    sessionExpired,
    rememberMe,
    onChange,
    username,
    password,
    recoveryEmail,
    onRemember,
    onSubmit,
    isRecoveryMode,
    onRecoverPasswordSubmit,
    onRecovery,
    onCancelRecovery,
    isLoginPage,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();

  const renderSubmitIcon = () => {
    if (loading) {
      return <StyledLoading small withOverlay={ false } />;
    }

    if (success) {
      return <CheckmarkFilled32 />;
    }

    return null;
  };

  const renderButtonMessage = () => {
    if (loading) {
      if (success) {
        return t('profile.loginSuccess');
      }
      return `${t('profile.loadingLogin')}...`;
    }
    //
    // if (error) {
    //   return t('profile.loginError');
    // }

    if (success) {
      return t('profile.loginSuccess');
    }

    return t('profile.login');
  };

  if (isRecoveryMode) {
    return (
      <StyledFormContainer
        className={ `formContainer reset ${ isLoginPage ? 'loginPage' : '' }` }
        theme={ theme }
        success={ recovered }
        error={ error }
      >
        <div className="messagePlaceholder">
          <h3 className="title">{ t('profile.passwordRecovery') }</h3>
          <p>
            { recovered
              ? t('profile.passwordRecoveryEmailSent')
              : t('profile.passwordRecoveryBody')
            }
          </p>
        </div>
        <Form method="post">
          <StyledTextInput
            autoFocus
            data-modal-primary-focus
            id={ uniqueId('email-') }
            name="email"
            disabled={ loading }
            labelText={ t('profile.emailPlaceholder') }
            placeholder={ t('profile.emailPlaceholder') }
            type="email"
            required
            onChange={ onChange('recovery_email') }
            value={ recoveryEmail }
            invalid={ !!error }
          />
          <p className="error" dangerouslySetInnerHTML={{ __html: error }} />
          <div className="buttons">
            <Button
              className="red submit"
              error={ error }
              type="submit"
              disabled={ loading }
              onClick={ onRecoverPasswordSubmit }
              kind="danger"
            >
              { loading && (
                <StyledLoading small withOverlay={ false } />
              ) }
              { t('common.agree') }
            </Button>
            <button
              className="backButton"
              type="button"
              disabled={ loading }
              onClick={ onCancelRecovery }
            >
              <IconCaret />{ t('common.backToPrevScreen') }
            </button>
          </div>
        </Form>
      </StyledFormContainer>
    );
  }

  return (
    <StyledFormContainer
      className={ `formContainer ${ isLoginPage ? 'loginPage' : '' }` }
      theme={ theme }
      success={ success }
      error={ error }
      sessionExpired={ sessionExpired }
    >
      <div className="messagePlaceholder">
        { title && (<h3 className="title">{ title }</h3>) }
        { message && <p dangerouslySetInnerHTML={{ __html: message }} /> }
        { !message && !successMessages?.length && (
          <>
            <h3 className="title">{ t('profile.welcome') }</h3>
            <p>{ t('profile.demandLogin') }</p>
          </>
        ) }
      </div>
      { (successMessages || []).map(msg => <p dangerouslySetInnerHTML={{ __html: msg }} />) }
      <Form method="post">
        <StyledTextInput
          autoFocus
          data-modal-primary-focus
          id={ uniqueId('email-') }
          name="email"
          disabled={ loading }
          labelText={ t('profile.emailPlaceholder') }
          placeholder={ t('profile.emailPlaceholder') }
          type="email"
          required
          onChange={ onChange('username') }
          value={ username }
          invalid={ !!error }
        />
        <StyledTextInput
          id={ uniqueId('password-') }
          type="password"
          disabled={ loading }
          labelText={ t('profile.passwordPlaceholder') }
          name="password"
          placeholder={ t('profile.passwordPlaceholder') }
          onChange={ onChange('password') }
          required
          value={ password }
          invalid={ !!error }
          invalidText={ error }
        />
        <div className="loginHelpers">
          <Checkbox
            id={ uniqueId('rememberMe') }
            name="_remember_me"
            labelText={ t('profile.rememberMe') }
            onChange={ onRemember }
            checked={ rememberMe }
            value={ rememberMe }
          />
          <Button
            kind="ghost"
            onClick={ onRecovery }
          >
            { t('profile.passwordForgotten') }
          </Button>
        </div>
        <Button
          className="submit"
          error={ error }
          type="submit"
          disabled={ loading }
          onClick={ onSubmit }
          kind="danger"
        >
          { renderSubmitIcon() }
          { renderButtonMessage() }
        </Button>
      </Form>
    </StyledFormContainer>
  );
};

LoginForm.propTypes = {
  title: string,
  onChange: func.isRequired,
  onRemember: func,
  onSubmit: func.isRequired,
  loading: bool,
  successMessages: array,
  sessionExpired: bool,
  success: bool,
  recovered: bool,
  rememberMe: bool,
  recoveryEmail: string,
  message: string,
  error: string,
  onRecovery: func,
  onCancelRecovery: func,
  onRecoverPasswordSubmit: func,
  isRecoveryMode: bool,
  username: string.isRequired,
  password: string.isRequired,
  isLoginPage: bool,
};

export default LoginForm;
