import React, { memo, useCallback } from 'react';
import { createContext } from 'use-context-selector';
import noop from 'lodash/noop';
import { node } from 'prop-types';
// import { useTranslation } from 'react-i18next';

import useRouter from 'Hooks/useRouter';
import fetch from 'Utils/fetch';

export const FetchContext = createContext(noop);

const requestMap = new Map();
const timeout = 150;
const dedupedFetch = (pathMach, retries, ...args) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      // if more retries have happened in the meantime, than the list will have more items
      // than stored in the retries constant created at the moment of creating this callback
      // in which case we reject this attempt and wait for the next setTimeout to trigger
      // otherwise, it means we can go ahead with the request;
      const totalRetries = requestMap.get(pathMach) || 0;
      if (totalRetries > retries) {
        // console.info(
        //   `%c${ pathMach }%c has been hit again in the last %c${ retries * timeout }ms. %cDe-duping request`,
        //   'color: green',
        //   '',
        //   'font-weight: bold',
        //   ''
        // );

        // Mimick an aborted request
        const error = new Error('De-duped request');
        error.code = 20;
        reject(error);
        return;
      }

      if (
        totalRetries > 1
        && process.env.NODE_ENV !== 'production'
      ) {
        console.info(
          `%c${ pathMach }%c has been de-duped %c${ totalRetries } %ctimes in the last %c${ totalRetries * timeout }ms.`,
          'color: green',
          '',
          'color: red',
          '',
          'font-weight: bold'
        );
      }

      // clear all mentions of base and perform request
      requestMap.delete(pathMach);
      resolve(fetch(...args));
    }, timeout);
  });
};

const FetchProvider = memo(({ children }) => {
  const { replace } = useRouter();
  // const { t } = useTranslation();

  const catchHandler = useCallback(
    err => {
      if (err.code === 20) return null;

      // todo: should use router location ?
      if (err.status === 401) {
        replace({
          pathname: window.location.pathname,
          search: window.location.search,
          hash: window.location.hash,
          state: {
            error: err.message || JSON.stringify(err),
            status: 401,
          }
        });
      } else if (err.location) {
        // TODO: can this be treated ?
        window.location.href = err.location;
      } else if (err.status === 404) {
        // TODO: treat 404
        console.warn(`Not Found`, err);
        // replace('/not-found');
      } else if (err.status >= 500) {
        // TODO: treat 5xx
        replace({
          pathname: window.location.pathname,
          search: window.location.search,
          hash: window.location.hash,
          state: {
            error: err.message || JSON.stringify(err),
            status: 500,
            networkError: err.info?.message || err.message
          }
        });
      }

      if (err.message.includes('An error occurred while fetching the data.')) {
        // err.message = t('common.apiError');
        // eslint-disable-next-line no-param-reassign
        // err.message = 'Unable to reach datacenter. Please try again in a few moments !';
      }

      throw err;
    },
    []
  );

  const fetchWrapper = useCallback(
    (...args) => {
      const path = args?.at(0);
      const opts = args?.at(1);
      if (
        typeof path === 'string'
        && (
          !opts?.method
          || opts.method === 'GET'
        )
      ) {
        const pathBase = path.match(/^\/(\w+)\/[(\w+)-_][^?]+(\/?)/);
        if (pathBase.length) {
          const pathMatch = pathBase?.at(0);
          const counter = requestMap.get(pathMatch) || 0;
          requestMap.set(pathMatch, counter + 1);
          return dedupedFetch(pathMatch, counter + 1, ...args).catch(catchHandler);
        }
      }
      return fetch(...args).catch(catchHandler);
    },
    []
  );

  return (
    <FetchContext.Provider value={ fetchWrapper }>
      { children }
    </FetchContext.Provider>
  );
});

FetchProvider.propTypes = {
  children: node
};
FetchProvider.displayName = 'FetchProvider';

export default FetchProvider;
