export const DEFAULT_LANG = 'ro';
export const FALLBACK_LANG = 'en';
export const SUPPORTED_LANGUAGES = [DEFAULT_LANG, FALLBACK_LANG];

export const TABLE_VIEW_MODE = 'table';
export const GRID_VIEW_MODE = 'grid';
export const LIST_VIEW_MODE = 'list';
export const VIEW_MODES = [TABLE_VIEW_MODE, GRID_VIEW_MODE, LIST_VIEW_MODE];

export const PROMOTION_PRGF = 'PRGF';
export const PROMOTION_BNDL = 'BNDL';
export const PROMOTION_N1 = 'N+1';
export const PROMOTION_DSC = 'DSC';
export const PROMOTION_TYPES = [PROMOTION_DSC, PROMOTION_PRGF, PROMOTION_BNDL, PROMOTION_N1];
export const NETWORK_ONE_DISTRIBUTION = 'Network One Distribution';

export const CURRENCY_HUF = 'huf';
export const CURRENCY_BGN = 'bgn';
export const CURRENCY_EUR = 'eur';
export const CURRENCY_USD = 'usd';
export const CURRENCY_RON = 'ron';

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const COUNTRIES = {
  21: {
    name: 'Bulgaria',
    currency: CURRENCY_BGN,
    symbol: 'BGN',
    zipCodeRegex: /^[0-9]{4}$/,
    zipCodePlaceholder: 'xxxx',
    zipCodeLength: 4,
    zipCodeRequired: false,
    phoneNumberRegex: /^(\+|)?([0-9]{10,15})$/
  },
  101: {
    name: 'Hungary',
    currency: CURRENCY_HUF,
    zipCodeRegex: /^[0-9]{4}$/,
    zipCodePlaceholder: 'xxxx',
    zipCodeLength: 4,
    zipCodeRequired: true,
    symbol: 'Ft',
    phoneNumberRegex: /^(\+|)?([0-9]{10,15})$/
  },
  182: {
    name: 'Poland',
    currency: CURRENCY_EUR,
    symbol: '€',
    zipCodeRegex: /^[0-9]{2}-[0-9]{3}$/,
    zipCodePlaceholder: 'xx-xxx',
    zipCodeLength: 6,
    zipCodeRequired: true,
    phoneNumberRegex: /^(\+|)?([0-9]{10,15})$/
  },
  192: {
    name: 'Romania',
    currency: CURRENCY_RON,
    symbol: 'Lei',
    zipCodeRegex: /^[0-9]{6}$/,
    zipCodePlaceholder: 'xxxxxx',
    zipCodeLength: 6,
    zipCodeRequired: true,
    phoneNumberRegex: /^(\+|)?([0-9]{9,13})$/
  }
};
