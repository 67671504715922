import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import roTranslations from 'Assets/translations/ro';
import enTranslations from 'Assets/translations/en';
import { DEFAULT_LANG, SUPPORTED_LANGUAGES, FALLBACK_LANG } from 'Constants';

const translation = lang => {
  class LanguageDetectorExt extends LanguageDetector {
    init(services, detection, opts) {
      super.init(services, detection, { ...(opts || {}), lng: lang });
    }
  }

  i18n
    .use(LanguageDetectorExt)
    .use(initReactI18next)
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      resources: {
        en: {
          translation: enTranslations,
        },
        ro: {
          translation: roTranslations,
        },
      },
      react: {
        useSuspense: false
      },
      preload: SUPPORTED_LANGUAGES,
      saveMissing: true,
      lng: lang || DEFAULT_LANG,
      fallbackLng: FALLBACK_LANG,
      supportedLngs: SUPPORTED_LANGUAGES,
      interpolation: {
        escapeValue: false
      },
      debug: process.env.NODE_ENV === 'development'
    });

  return i18n;
};

export default translation;
