import amplitude from 'amplitude-js';
import { memoize } from 'lodash';

const initialize = memoize(() => {
  const key = process.env.AMPLITUDE_API_KEY;
  if (!key) return;

  amplitude.getInstance().init(key, undefined, {
    // optional configuration options
    includeUtm: true,
    includeGclid: true,
    includeReferrer: true,
    defaultTracking: {
      pageViews: true,
      sessions: true,
      formInteractions: true,
      fileDownloads: true,
    },
  });
});

export const trackEvent = (name, data) => {
  initialize();

  amplitude.getInstance().logEvent(name, data);
};

export const setUserForTracking = (id, impersonated) => {
  initialize();

  amplitude.getInstance().setUserId(id);
  amplitude.getInstance().setUserProperties({ impersonated });
};
