import React, { memo } from 'react';
import { Global, useTheme, css } from '@emotion/react';
import { string, arrayOf } from 'prop-types';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import AuthHOC from 'Containers/Auth/Auth';
import LoginModal from 'Components/Login/LoginModal';
import { NETWORK_ONE_DISTRIBUTION } from 'Constants';

import Logo from 'Assets/images/logo-nod.png';
import Logo2x from 'Assets/images/logo-nod@2x.png';

const LoginPage = memo((
  { loginError = '', successMessages = [], errorMessages = [] }
) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { bodyAttributes } = Helmet.peek() || {};

  let loginPageClassNames = bodyAttributes?.class || '';
  if (!loginPageClassNames.includes('LoginPage')) {
    loginPageClassNames += 'LoginPage';
  }

  return (
    <>
      <Helmet>
        <body className={ loginPageClassNames } />
        <title>{ t('titles.loginPage') }</title>
      </Helmet>
      <Global
        styles={
          css`
            .LoginPage {
              width: 100vw;
              height: 100vh;
              background-color: ${ theme.colors.white };
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .bx--modal-container {
              box-shadow: 0 3px 15px ${ theme.colors.gray };
              max-width: 680px;
            }

            .logoContainer {
              position: relative;
              width: 100%;
              min-width: 215px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              padding: 64px 20px 40px;

              img {
                display: block;
                max-width: 150px;
                height: auto;
              }

              p {
                width: 100%;
                margin: 0 auto;
                padding: 0;
                position: absolute;
                left: 50%;
                bottom: 15px;
                transform: translateX(-50%);
                font-size: 0.65rem;
                text-align: center;
                white-space: pre-wrap;
              }
            }
            @media screen and (max-width: 767px) {
              .logoContainer {
                padding: 40px 20px 32px;
              }
            }
          `
        }
      />
      <AuthHOC>
        { props => {
          if (
            // eslint-disable-next-line react/prop-types
            !props.error
            && (
              loginError.length
              || errorMessages.length
            )
          ) {
            // eslint-disable-next-line no-param-reassign
            props.error = loginError || errorMessages.join(' ');
          }
          return (
            <LoginModal
              open
              size="md"
              isLoginPage
              // sessionExpired={ !!loginError || errorMessages.length > 0 }
              successMessages={ successMessages }
              { ...props }
            >
              <div className="logoContainer">
                <img
                  src={ Logo2x }
                  srcSet={ `${Logo} 149w, ${Logo2x} 297w` }
                  sizes="(max-width: 600px) 149px, 297px"
                  alt={ NETWORK_ONE_DISTRIBUTION }
                />
              </div>
            </LoginModal>
          );
        } }
      </AuthHOC>
    </>
  );
});

LoginPage.propTypes = {
  loginError: string,
  errorMessages: arrayOf(string),
  successMessages: arrayOf(string),
};
LoginPage.displayName = 'LoginPage';

export default LoginPage;
