import React from 'react';
import { renderToString } from 'react-dom/server';
import { I18nextProvider } from 'react-i18next';
import { StaticRouter } from 'react-router-dom/server';
import { BrowserRouter } from 'react-router-dom';

import i18n from 'Providers/i18n/TranslationProvider';
import UserProvider from 'Contexts/UserContext';
import ThemeProvider from 'Providers/theme/ThemeProvider';
import FetchProvider from 'Contexts/FetchContext';
import ModalsProvider from 'Contexts/ModalsContext';
import disableReactDevTools from 'Utils/disableReactDevTools';
import LoginPage from 'Pages/Login';
import { DEFAULT_LANG } from 'Constants';

export default (initialProps, context) => {
  if (context && context.serverSide) {
    const renderedHtml = {
      componentHtml: renderToString(
        <I18nextProvider i18n={ i18n(initialProps?.user?.language || DEFAULT_LANG) }>
          <StaticRouter
            basename={ context.base || '/' }
            location={ {
              pathname: context.pathname,
              search: context.search
            } }
          >
            <FetchProvider>
              <UserProvider user={ initialProps?.user || {} }>
                <ThemeProvider>
                  <ModalsProvider>
                    <LoginPage
                      loginError={ initialProps.loginError }
                      successMessages={ initialProps.successMessages }
                      errorMessages={ initialProps.errorMessages }
                    />
                  </ModalsProvider>
                </ThemeProvider>
              </UserProvider>
            </FetchProvider>
          </StaticRouter>
        </I18nextProvider>
      ),
      title: 'Login'
    };
    return { renderedHtml };
  }

  return () => (
    <I18nextProvider i18n={ i18n(initialProps?.user?.language || DEFAULT_LANG) }>
      <BrowserRouter basename={ context ? context.base : '/' }>
        <FetchProvider>
          <UserProvider user={ initialProps?.user }>
            <ThemeProvider>
              <ModalsProvider>
                <LoginPage
                  loginError={ initialProps.loginError }
                  successMessages={ initialProps.successMessages }
                  errorMessages={ initialProps.errorMessages }
                />
              </ModalsProvider>
            </ThemeProvider>
          </UserProvider>
        </FetchProvider>
      </BrowserRouter>
    </I18nextProvider>
  );
};

if (process.env.NODE_ENV === 'production') disableReactDevTools();
